*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  background-color: transparent;
}
:root {
  --clr-primary-dark: #010001;
  --clr-primary: #333333;
  --clr-primary-light: #3d3d3d;
  --clr-secondary-dark: #701f10;
  --clr-secondary: #C6371C;
  --clr-secondary-light: #e14629;
  --clr-light: #f7f7f7;
  --clr-grey-100: #ededed;
  --clr-grey-200: #dadada;
  --clr-grey-300: #d0d0d0;
  --clr-grey-400: #c6c6c6;
  --clr-grey-500: #818181;
  --clr-grey-600: #505050;
  --clr-grey-700: #474747;
  --clr-grey-800: var(--clr-primary-light);
  --clr-dark: var(--clr-primary);
  --clr-xdark: var(--clr-primary-dark);

  --clr-fade: rgba(255, 255, 255, 0.12);

  --clr-success: #198038;
  --clr-warning: #f1c21b;
  --clr-error: #da1e28;

  --h-font: 'Montserrat', sans-serif;
  --p-font: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --c-font: monospace, sans-serif;
}
html {
  scroll-behavior: smooth;
  font-size: 14px;
}
body {
  font-family: var(--p-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: var(--c-font);
}

/*** GLOBAL ***/
.bg-dark {
  background-color: var(--clr-xdark);
  color: var(--clr-grey-100);
}
img {
  display: block;
  max-width: 100%;
}
.w100 {
  width: 100%;
}

/*** BREAKPOINTS ***/
.--sm,
.--md,
.--lg,
.--xlg {
  display: none;
}

@media (min-width: 600px) {
  .--xs {
    display: none;
  }
  .--sm {
    display: unset;
  }
}
@media (min-width: 900px) {
  .--md {
    display: unset;
  }
}
@media (min-width: 1200px) {
	.--lg {
		display: unset;
	}
}
@media (min-width: 1536px) {
	.--xlg {
		display: unset;
	}
}

/*** TYPOGRAPHY ***/
svg {
  color: currentColor;
  fill: currentColor;
  display: inline-block;
}
a {
  cursor: pointer;
}
h1, h2, h3, h4, h5, h6, .-as-h {
  font-family: var(--h-font);
  line-height: 1;
  hyphens: auto;
}
h1 {
  font-weight: 600;
  letter-spacing: .08em;
  font-size: 2rem;
}
h2 {
  font-weight: 600;
  letter-spacing: .05em;
  font-size: 1.1rem;
}
h3 {
  font-weight: 600;
  letter-spacing: .03em;
  font-size: .9rem;
  max-width: 25ch;
}
h4 {
  font-weight: 600;
  font-size: 1rem;
  max-width: 25ch;
  letter-spacing: .02em;
}
p, .-as-p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 400;
}
p, li, a, .-as-p {
  line-height: 1.46429em;
  letter-spacing: .02em;
  max-width: 60ch;
}
.capitalize {
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
.--unit {
  font-size: .8rem;
  line-height: 1.46429em;
  letter-spacing: .02em;
  font-weight: 600;
}


/*** FLEX ***/
.flex {
  display: flex;
}
.fcol {
  flex-direction: column;
}
.fspbw {
  justify-content: space-between;
}
.fend {
  justify-content: flex-end;
}
.fvcent {
  align-items: center;
}
.fvend {
  align-items: flex-end;
}
.fcol {
  flex-direction: column;
}
.fwrap {
  flex-wrap: wrap;
}
.fcent {
  justify-content: center;
}
.fgap-sm {
  gap: .5rem;
}
