.text-panel ul {
    list-style-type: square;
    margin-top: 1rem;
    margin-left: 2rem;
}
.text-panel p {
    margin-bottom: 0;
}
.text-panel h3 {
    color: var(--clr-secondary-light);
}