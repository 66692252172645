.chart-container {
    max-width: 100%;
    max-height: 100%;
}
.stacked-pie-chart {
    width: 200px;
    height: 200px;
}

.stacked-bar-chart {
    width: 100%;
    max-width: 200px;
    height: 200px;
}

.line-chart {
    width: 100%;
    max-width: 100%;
    height: 200px;
}

@media (min-width: 600px) {
    .stacked-pie-chart {
        width: 400px;
        height: 400px;
    }
    .stacked-bar-chart {
        max-width: 280px;
        height: 280px;
    }
}
@media (min-width: 1000px) {
    .stacked-pie-chart {
        width: 400px;
        height: 400px;
    }
}
@media (min-width: 1300px) {
    .stacked-pie-chart {
        width: 300px;
        height: 300px;
    }
}
@media (min-width: 1900px) {
    .stacked-pie-chart {
        width: 600px;
        height: 400px;
    }
}