.navigation {
    background-color: var(--clr-primary-dark);
}
.navigation > nav {
    position: relative;
}
.mobile-trip-handler-label {
    display: block;
}
.mobile-menu {
    background-color: var(--clr-primary-dark);
    position: relative;
}
.mobile-menu.--active {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
}
.mobile-nav {
    width: 100%;
    border-top: 1px solid var(--clr-fade);
    border-bottom: 1px solid var(--clr-fade);
}
.mobile-nav li {
    max-width: 100%;
}