.accordion-header-p {
    margin: 0;
    padding: 0;
    font-size: .8rem;
}
.color-helper {
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%);
    height: .5rem;
    width: .5rem;
    border-radius: 50%;
    color: inherit;
    background-color: currentColor;
}