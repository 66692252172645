.map-container {
    height: 100%;
    min-height: 250px;
}
.leaflet-container {
    height: 100%;
}

@media (min-width: 600px) {
    .map-container {
        height: 250px;
    }
}