.step h4 {
    margin-bottom: .5rem;
}
.step h4:not(.first-h) {
    margin-top: 1rem;
}
@media (min-width: 1200px) {
    .center-panel .step h4 {
        margin-top: 0;
    }
    .center-panel .bin-step {
        display: grid;
        grid-gap: .5rem;
        grid-template-columns: 1fr 1fr;
    }
}