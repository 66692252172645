footer {
    font-size: min(.9rem, 3vw);
    height: 3em;
    min-height: 3em;
    background-color: var(--clr-primary-dark);
    border-top: 1px solid var(--clr-grey-800);
}
footer p {
    margin: 0;
    padding: 0;
    font-size: inherit;
    color: var(--clr-grey-200);
}