.app-container {
    position: relative;
    --side-bar-x: min(250px, 100%);
}
.main-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}
main {
    flex-grow: 1;
}
.main-grid-center {
    grid-column: span 12;
}
.main-grid-side {
    grid-column: span 12;
    min-width: var(--side-bar-x);
}
.center-panel {
    border: 1px solid var(--clr-grey-800);
    border-bottom-width: 0;
    min-width: var(--side-bar-x);
    min-height: 400px;
    max-height: 100%;
    background-color: var(--clr-primary-dark);
    position: relative;
}
.center-panel .--secondary {
    padding-left: 1rem;
    padding-right: 1rem;
}
.side-panel {
    border-top: 1px solid var(--clr-grey-800);
}
.panel-group {
    background-color: #121212;
    padding: 1rem;
    border: 1px solid var(--clr-fade);
}
.panel-header {
    padding: 2rem 1rem 1rem 1rem;
}
.panel-header h2 {
    color: var(--clr-secondary-light);
}
.padding-block {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.padding-inline {
    padding-right: 1rem;
    padding-left: 1rem;
}
@media (min-width: 600px) {
    .center-panel .--secondary {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
@media (min-width: 1200px) {
    .app-container {
        min-height: 100vh;
        max-height: 100vh;
        height: 100vh;
    }
    main {
        max-height: calc(100vh - 10.8rem);
    }
    .center-panel,
    .side-panel {
        overflow-y: auto;
    }
    .main-grid-center {
        grid-column: span 8;
    }
    .main-grid-side {
        grid-column: span 2;
        min-width: var(--side-bar-x);
    }
}