a.navlink {
    display: block;
    text-decoration: none;
    padding: .5rem 1rem;
    opacity: .8;
    border: 1px solid transparent;
}
a.navlink:hover,
a.navlink:focus {
    background-color: var(--clr-primary);
    opacity: 1;
}
a.navlink.--active {
    background-color: var(--clr-secondary-dark);
    opacity: 1;
    border-color: var(--clr-secondary);
}