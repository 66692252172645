.calculator-status {
    position: absolute;
    top: 0;
    left: 0;
    padding: .5rem;
    width: 100%;
    z-index: 2;
}
.inner-center-panel .panel-header h2 {
    font-size: 1.5em;
}
.inner-center-panel .grid-child-grow-fill {
    grid-column: span 2;
}

@media (min-width: 1200px), (min-width: 600px) and (max-width: 900px) {
    .inner-center-step-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        grid-auto-flow: dense;
    }
    .inner-center-step-container > div.grid-child-grow-medium-lg,
    .inner-center-step-container > div.grid-child-grow-sub-medium {
        grid-column: span 2;
    }
}
@media (min-width: 1300px) {
    .inner-center-step-container > div.grid-child-grow-sub-medium {
        grid-column: span 1;
    }
}
@media (min-width: 1900px) {
    .inner-center-step-container {
        grid-template-columns: repeat(5, 1fr);
    }
    .inner-center-panel .grid-child-grow-fill {
        grid-column: span 5;
    }
    .inner-center-step-container > div.grid-child-grow-medium-xxl {
        grid-column: span 2;
    }
}