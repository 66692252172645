.add-tile {
    background-color: var(--clr-primary);
    border: 1px solid var(--clr-fade);
    border-right: 0;
    cursor: pointer;
    outline: none;
}
.add-tile:hover,
.add-tile:focus-visible {
    background-color: var(--clr-primary-light);
}
.add-tile-label {
    padding-left: .4rem;
    padding-right: .4rem;
}